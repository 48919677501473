.form-header {
  border-bottom: 5px solid #1677ff80;
  margin: 0 -15px;
  padding: 0 15px;
  min-height: 63px;
}

.form-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.menuItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
}

.menuItemLabel {
  position: relative;
  max-height: 30px;
  top: -16px;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.loginHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  width: 100%;
  margin: 7vh auto;
}

.loginLogo {
  height: 30px;
  margin-left: 10px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  padding: 5vh 1.25rem 10vh;
  background-color: #ffffff;
  margin: 5vh 0;
}

.loginFormField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.loginTitle {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0 auto 20px;
}

.loginSubmitButton {
  width: 100%;
  font-weight: bold;
}

.loginForgotPassword {
  width: 100%;
  font-size: 14px;
  margin-left: 15px;
}

.loginRegistrationTerms {
  font-size: 12px;
  margin: 10px auto;
}

.loginFooter {
  width: 100%;
  background-color: #f4f7f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginFooterContact {
  font-size: 18px;
  margin: 30px auto 5px;
}

.loginFooterEmail {
  font-size: 16px;
  margin: 10px auto 20px;
}

.loginFooterCopyright {
  font-size: 14px;
  margin: 10px auto 20px;
}

.loginInputIcon {
  margin-right: 10px;
  margin-left: 4px;
}

/* Basic table style */
.ant-table-tbody > .ant-table-row:hover {
  box-shadow:
    0 -3px 6px 0 rgba(0, 0, 0, 0.15) /* Тень сверху */,
    0 3px 6px 0 rgba(0, 0, 0, 0.15); /* Тень снизу */
}

.ant-table-tbody > .ant-table-row:hover > .ant-table-cell {
  border-left-color: transparent !important; /* Устанавливает цвет левой границы ячейки таблицы в прозрачный */
  border-right-color: transparent !important; /* Устанавливает цвет правой границы ячейки таблицы в прозрачный */
}

/* Editable table style */
.editable-table .ant-table-tbody > .ant-table-row:hover {
  box-shadow: none; /* Отключает тень вокруг строки при наведении */
}

.editable-table .ant-table-tbody > .ant-table-row:hover > .ant-table-cell {
  border-left-color: #f0f0f0 !important;
  border-right-color: #f0f0f0 !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

/* Page form style */
.page-form-style {
  min-height: 70vh;
  background-color: white;
  border-radius: 7px;
  padding: 15px;
}

/* Form style */
.form-style {
  margin-top: 10px;
}

/* ant-input, ant-input, ant-picker */
/* Стили для ввода */
.form-style .ant-input,
.form-style .ant-input-number,
.form-style .ant-picker {
  border: none; /* Убирает все границы элемента */
  border-radius: 0; /* Убирает скругление углов */
  box-shadow: none; /* Удаляет тень вокруг элемента */
  border-bottom: 2px solid #d9d9d9; /* Серый цвет нижней границы */
}

/* Стили при наведении */
.form-style .ant-input:hover,
.form-style .ant-input-number:hover,
.form-style .ant-picker:hover {
  box-shadow: none; /* Удаляет тень вокруг элемента */
  border-bottom: 2px solid #a9a9a9; /* Серый цвет нижней границы */
}

/* Стили при фокусе */
.form-style .ant-input:focus,
.form-style .ant-input-number-focused,
.form-style .ant-picker-focused {
  box-shadow: none; /* Удаляет тень вокруг элемента */
  border-bottom: 2px solid #1677ff !important; /* Синий цвет для границы */
}

/* Стили для состояния ошибки */
/* Стили для полей ввода */
.form-style .ant-form-item-has-error .ant-input,
.form-style .ant-form-item-has-error .ant-input-number,
.form-style .ant-form-item-has-error .ant-select .ant-select-selector {
  border: none;
  border-bottom: 2px solid #f4867b !important;
  box-shadow: none;
  outline: none;
}

/* Стили при наведении */
.form-style .ant-form-item-has-error .ant-select:hover .ant-select-selector {
  border-bottom: 2px solid #f4867b !important;
}

/* Стили при фокусе */
.form-style .ant-form-item-has-error .ant-input:focus,
.form-style .ant-form-item-has-error .ant-input-number-focused,
.form-style .ant-form-item-has-error .ant-select-open .ant-select-selector {
  box-shadow: none !important;
  outline: none;
  border-bottom: 2px solid #ff4c51 !important;
}

/* select */
.form-style .ant-select-selector {
  border: none !important; /* Убирает все границы элемента */
  border-radius: 0 !important; /* Убирает скругление углов */
  box-shadow: none !important; /* Удаляет тень вокруг элемента */
  border-bottom: 2px solid #d9d9d9 !important; /* Серый цвет нижней границы */
}

.form-style .ant-select-focused .ant-select-selector {
  border-bottom: 2px solid #1677ff !important; /* Синий цвет для границы */
}

.form-style .ant-select:hover .ant-select-selector {
  border-bottom: 2px solid #a9a9a9 !important; /* Серый цвет нижней границы */
}

.close-icon-hover {
  cursor: pointer;
  border-radius: 50%; /* Создает круглую форму */
  padding: 10px; /* Расстояние от иконки до границ фона */
  width: 40px; /* Явное задание ширины */
  height: 40px; /* Явное задание высоты */
  /* Центрирование содержимого элемента, если требуется */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-in-out; /* Плавное появление фона */
}

.close-icon-hover:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Полупрозрачный фон при наведении */
}

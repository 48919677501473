/* App style */
.app-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0 20px 0 0;
  justify-content: space-between;
  height: 72px;
}

.logo-container {
  position: relative;
  margin-right: 30px;
  height: 72px;
}

.logo-header {
  position: relative;
  max-height: 60px;
  top: 7px;
  right: -3px;
}

.logo-beta {
  position: absolute;
  top: -22px;
  left: 169px;
  color: #a5adb5;
}

.app-content {
  padding: 0 10px;
  margin: 0 30px;
  /*margin: 25px 30px;*/
  /*!*overflow: auto;*!   Разобраться с пустым местом справа  */
}

.app-footer {
  text-align: center;
  height: 100px;
}

.content-filter-bar-detail-page {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.registrationForm {
  width: 100%;
  height: 520px;
  background-color: #ffffff;
  padding: 2vw 3vw;
  border-radius: 20px;
}

.modalTitle {
  text-align: center;
  margin: 10px auto 0;
  font-size: 30px;
  font-weight: bold;
}

.submitButton {
  margin-top: 15px;
  height: 55px;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
}

.loginPrompt {
  font-size: 14px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}

.loginLink {
  text-decoration: underline;
}

.loginPrompt:hover {
  text-decoration: none;
}

.termsText {
  font-size: 12px;
  margin: 10px auto;
}

.inputIcon {
  margin-right: 10px;
  margin-left: 4px;
}
